@keyframes swing {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

header#nav {
  form#text-search .border-muted {
    border-bottom: 0 !important;
  }

  #categories_dropdown {
    button:focus {
      box-shadow: none;
    }
    .dropdown-menu {
      &.show {
        left: -5px !important;
        top: -2px !important;
        z-index: -1;
        .category .subcategories {
          z-index: 100;
          min-width: 200px;
          right: -200px;
          top: 0;
        }
        @include media-breakpoint-up(sm) {
          .category:hover .subcategories {
            display: block !important;
          }
        }
      }
      @include media-breakpoint-down(sm) {
        position: fixed !important;
        max-height: calc(100vh - 50px);
        overflow: scroll;
        width: 100vw;
        &.show {
          transform: translate3d(
            -5px,
            50px,
            0px
          ) !important;
          left: 5px !important;
          right: 0px !important;
          top: -3px !important;
          z-index: 110;
        }
      }
    }
  }

  #navCart {
    .badge {
      top: -6px;
      right: -10px;
      padding: 4px 4px;
      &.animate {
        animation: swing 0.3s ease-in-out 1
          alternate;
      }
    }
  }
}
