#companyPage {
  font-size: 1.2rem;
  form {
    font-size: 1rem;
  }
  svg {
    max-width: 100%;
  }
  ul.customBullets {
    list-style: none; /* Remove default bullets */
    li::before {
      content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: $red; /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
  }

  #company-list {
    li {
      position: relative;
      &:after {
        content: "";
        display: inline-block;
        background-color: $red;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        right: -5px;
        top: calc(50% - 5px);
        position: absolute;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
      &:last-of-type::after {
        display: none;
      }
    }
  }
  .circleVisual {
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 170px;
      height: 170px;
      position: absolute;
      border-radius: 50%;
      background-color: $red;
      right: -30px;
      top: calc(50% - 85px);
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}
