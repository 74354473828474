button.noStyle {
  background-color: inherit;
  border: none;
  &:active,
  &:focus {
    outline: none;
  }
}

svg.toggleArrow {
  transition: all 0.2s ease-in-out;
  &.active {
    transform: rotate(180deg);
  }
}

label.required:after {
  content: "*";
  display: inline-block;
  margin-left: 2px;
}

.nav-tabs .nav-item a {
  cursor: pointer;
  &.completed {
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2338a16a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right
      calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
  }
}

main {
  min-height: 380px;
  @include media-breakpoint-up(lg) {
    min-height: 55vh;
  }
}

.bg-lightGray {
  background-color: $gray100;
}

.bg-black {
  background-color: $black;
}

.row.products > div[class*="col-"] {
  display: flex;
  flex: 1 0 auto;
}

.text-decoration-underline {
  text-decoration: underline;
}

.border-left-lg {
  @include media-breakpoint-up(lg) {
    border-left: solid 1px $border-color;
  }
}

.bg-blue-100 {
  background-color: lighten($blue, 35%);
}

.shadow-0 {
  box-shadow: none !important;
}

.lead strong {
  font-weight: 600;
  color: $red;
}

.w-lg-75 {
  @include media-breakpoint-up(md) {
    width: 75%;
  }
}

.w-lg-50 {
  @include media-breakpoint-up(md) {
    width: 50%;
  }
}

.border-bottom-lg-0 {
  @include media-breakpoint-up(lg) {
    border-bottom: 0px !important;
  }
}

.text-dark {
  color: #343a40 !important;
}

.btn.btn-success {
  color: white;
}